import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppFilterPipeModule } from '../_filters/app.filter-pipe.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PnfComponent } from './pnf/pnf.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { STableComponent } from './s-table/s-table.component';
import { ModalComponent } from './modal.component';
import { TableComponent } from './table/table.component';
import { AgentsComponent } from './agents/agents.component';
import { SShimmerComponent } from './s-shimmer/s-shimmer.component';
import { ReportCardTableComponent } from './report-card-table/report-card-table.component';
import { SExpTableComponent } from './s-exp-table/s-exp-table.component';
import { DynamicFormComponent } from './forms/dynamic-form/dynamic-form.component';
import { DiscoverySettingsComponent } from './discovery-settings/discovery-settings.component';
import { DynamicControlComponent } from './forms/dynamic-control/dynamic-control.component';
import { CustomCalendarComponent } from './forms/custom-calendar/custom-calendar.component';
import { PillCardComponent } from './pill-card/pill-card.component';
import { SCrudTComponent } from './s-crud-t/s-crud-t.component';
import { STShimmerComponent } from './s-t-shimmer/s-t-shimmer.component';
import { IntegrationActionComponent } from './integration-action/integration-action.component';
import { EvidenceComponent } from './evidence/evidence.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { CompanyMappingComponent } from './integrations/company-mapping/company-mapping.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertsNotificationComponent } from './alerts-notification/alerts-notification.component';
import { ApplicationBaselineComponent } from './application-baseline/application-baseline.component';
import { VulnerabilityStatsComponent } from './vulnerability-stats/vulnerability-stats.component';
import { NonpsaCompanyMappingComponent } from './integrations/nonpsa-company-mapping/nonpsa-company-mapping.component';
import { NetworksScanComponent } from './networks-scan/networks-scan.component';
import { NetworkassetComponent } from './networkasset/networkasset.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { VulnerabilityOverviewComponent } from './vulnerability-overview/vulnerability-overview.component';
import { InsightsComponent } from './insights/insights.component';
import { StandardReportsComponent } from './standard-reports/standard-reports.component';
import { DiscoveryTimeoutComponent } from './discovery-timeout/discovery-timeout.component';
import { DefaultTemplatesComponent } from './integrations/default-templates/default-templates.component';
import { AssetsByVulsComponent } from './assets-by-vuls/assets-by-vuls.component';
import { NetworkvulsComponent } from './networkvuls/networkvuls.component';
import { Log4jEmailNotifyComponent } from './log4j-email-notify/log4j-email-notify.component';
import { OverviewComponent } from './overview/overview.component';
import { SuppressVulsDaysComponent } from './suppress-vuls-days/suppress-vuls-days.component';
import { IprangeComponent } from '../admin/settings/iprange/iprange.component';
import { SnmpCredentialsComponent } from '../admin/settings/snmp-credentials/snmp-credentials.component';
import { AdCredentialsComponent } from '../admin/settings/ad-credentials/ad-credentials.component';
import { MasterCredentialsComponent } from '../admin/settings/master-credentials/master-credentials.component';
import { AssetCredentialsComponent } from '../admin/settings/asset-credentials/asset-credentials.component';
import { Snmpv2Component } from '../admin/settings/snmpv2/snmpv2.component';
import { Snmpv3Component } from '../admin/settings/snmpv3/snmpv3.component';
import { ProbesAgentComponent } from './probes-agent/probes-agent.component';
import { ReportSchedulerComponent } from './standard-reports/report-scheduler/report-scheduler.component';
import { JobsComponent } from './jobs/jobs.component';
import { PatchJobsComponent } from './patch-jobs/patch-jobs.component';
import { ReportFiltersComponent } from './report-filters/report-filters.component';
import { ExcludeIpComponent } from '../admin/settings/exclude-ips/exclude-ip.component';
import { TicketingComponent } from './ticketing/ticketing.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PiiScanComponent } from './pii-scan/pii-scan.component';
import { PdscanResultComponent } from './pdscan-result/pdscan-result.component';
import { ReportJobsComponent } from './standard-reports/report-jobs/report-jobs.component';
import { OfflineVulnerabilityScanJobsComponent } from './jobs/offline-vulnerability-scan-jobs/offline-vulnerability-scan-jobs.component';
import { NonPsaEventCompanyMappingComponent } from './integrations/non-psa-event-company-mapping/non-psa-event-company-mapping.component';
import { AgentEventLogsComponent } from './jobs/agent-event-logs/agent-event-logs.component';
import { FormDataCheckDirective } from './forms/form-data-check.directive';
import { ComplianceRemediationComponent } from './compliance-remediation/compliance-remediation.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { PatchingSchedulerComponent } from './schedule/patching-scheduler/patching-scheduler.component';
import { SchedulerDetailsComponent } from './schedule/scheduler-details/scheduler-details.component';
import { GlobalReportSchedulerComponent } from './schedule/global-report-scheduler/global-report-scheduler.component';
import { AttackSurfaceComponent } from './attack-surface/attack-surface.component';
import { IpvComponent } from './ipv/ipv.component';
import { StatCountComponent } from './stat-count/stat-count.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { GlobalReportBuilderSchedulerComponent } from './schedule/global-report-builder-scheduler/global-report-builder-scheduler.component';
import { MigrateCompanyComponent } from './migrate-company/migrate-company.component';
import { DirectivesModule } from "../-directives/-directives.module";
import { DomainConfigurationComponent } from './attack-surface/domain-configuration/domain-configuration.component';
import { AttackSurfaceResultComponent } from './attack-surface/attack-surface-result/attack-surface-result.component';
import { AttackSurfaceMapperJobComponent } from './attack-surface/attack-surface-mapper-job/attack-surface-mapper-job.component';
import { AttackSurfaceResultTabComponent } from './attack-surface/attack-surface-result-tab/attack-surface-result-tab.component';
import { ApplicationPatchComponent } from './application-patch/application-patch.component';



@NgModule({
  declarations: [
    ConfirmDialogComponent,
    PnfComponent, UnauthorizedComponent,
    STableComponent,
    ModalComponent,
    TableComponent,
    SShimmerComponent,
    ReportCardTableComponent,
    IprangeComponent,
    SnmpCredentialsComponent,
    AdCredentialsComponent,
    MasterCredentialsComponent,
    SExpTableComponent,
    DynamicFormComponent,
    DynamicControlComponent,
    CustomCalendarComponent,
    PillCardComponent,
    SCrudTComponent,
    STShimmerComponent,
    IntegrationActionComponent,
    EvidenceComponent,
    AgentsComponent,
    Snmpv2Component,
    Snmpv3Component,
    ExcludeIpComponent,
    AssetCredentialsComponent,
    DiscoverySettingsComponent,
    JobDetailsComponent,
    IntegrationsComponent,
    CompanyMappingComponent,
    AlertsComponent,
    AlertsNotificationComponent,
    ApplicationBaselineComponent,
    VulnerabilityStatsComponent,
    NonpsaCompanyMappingComponent,
    NetworkassetComponent,
    NetworksScanComponent,
    GettingStartedComponent,
    VulnerabilityOverviewComponent,
    InsightsComponent, StandardReportsComponent, DiscoveryTimeoutComponent,
    DefaultTemplatesComponent, AssetsByVulsComponent, NetworkvulsComponent, Log4jEmailNotifyComponent,
    OverviewComponent, ReportFiltersComponent,
    SuppressVulsDaysComponent,
    ProbesAgentComponent,
    ReportSchedulerComponent,
    JobsComponent,
    PatchJobsComponent,
    ReportFiltersComponent,
    TicketingComponent,
    PiiScanComponent,
    PdscanResultComponent,
    ReportJobsComponent,
    OfflineVulnerabilityScanJobsComponent,
    NonPsaEventCompanyMappingComponent,
    AgentEventLogsComponent,
    FormDataCheckDirective, StatCountComponent, IpvComponent, CustomTableComponent,
    ComplianceRemediationComponent, ScheduleComponent, AttackSurfaceComponent,
    PatchingSchedulerComponent, SchedulerDetailsComponent, GlobalReportSchedulerComponent, GlobalReportBuilderSchedulerComponent, MigrateCompanyComponent, AttackSurfaceMapperJobComponent, DomainConfigurationComponent, AttackSurfaceResultComponent, AttackSurfaceResultTabComponent, ApplicationPatchComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    FlexLayoutModule,
    AppFilterPipeModule,
    NgxMatSelectSearchModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularEditorModule,
    DirectivesModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    ConfirmDialogComponent,
    PnfComponent, UnauthorizedComponent,
    STableComponent,
    TableComponent,
    ModalComponent,
    SShimmerComponent, ReportCardTableComponent,
    SExpTableComponent, AgentsComponent, DiscoverySettingsComponent, IprangeComponent,
    SnmpCredentialsComponent,
    Snmpv2Component,
    Snmpv3Component,
    ExcludeIpComponent, PiiScanComponent,
    AssetCredentialsComponent,
    AdCredentialsComponent,
    MasterCredentialsComponent,
    DynamicFormComponent,
    DynamicControlComponent,
    CustomCalendarComponent, PillCardComponent, SCrudTComponent, STShimmerComponent,
    IntegrationActionComponent, EvidenceComponent, JobDetailsComponent, IntegrationsComponent,
    CompanyMappingComponent, AlertsComponent, AlertsComponent, AlertsNotificationComponent,
    ApplicationBaselineComponent, NetworksScanComponent, NetworkassetComponent, GettingStartedComponent,
    VulnerabilityOverviewComponent, InsightsComponent, StandardReportsComponent, DiscoveryTimeoutComponent,
    Log4jEmailNotifyComponent, OverviewComponent, ReportFiltersComponent, SuppressVulsDaysComponent, ProbesAgentComponent,
    ApplicationPatchComponent,
    JobsComponent,
    PatchJobsComponent, PdscanResultComponent,
    JobsComponent, ReportJobsComponent,
    TicketingComponent, FormDataCheckDirective,
    ComplianceRemediationComponent, ScheduleComponent, AttackSurfaceComponent,
    PatchingSchedulerComponent, SchedulerDetailsComponent, GlobalReportSchedulerComponent, SchedulerDetailsComponent, MigrateCompanyComponent
  ],
  entryComponents: [ConfirmDialogComponent, DiscoverySettingsComponent, IprangeComponent,
    SnmpCredentialsComponent,
    AdCredentialsComponent, Snmpv2Component,
    Snmpv3Component, ExcludeIpComponent,
    AssetCredentialsComponent,
    MasterCredentialsComponent, IntegrationActionComponent]
})

export class SharedModule {
}
