<div class="remedia-container">
  <mat-sidenav-container class="remedia-sidenav-container h-100vh">
    <mat-sidenav-content>
      <mat-card>
        <mat-card-header class="bb-1">
          <div class="flex-container">
            <div class="left-box">
              <span class="small">
                <mat-form-field appearance="outline" class="w-100 wider-form-field">
                  <mat-label>Choose {{currentIntegration.title}} Credential:</mat-label>
                  <mat-select [multiple]="false" placeholder="Choose credential" [(ngModel)]="currentCredential"
                    name="currentCred" (ngModelChange)="getIntegrationMapping(); getCompanyActionParams()">
                    <mat-option *ngFor="let item of integrationsData" [value]="item._id"> {{item.name}} </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
            <div *ngIf="currentIntegration.name == 'Autotask'">
            <div class="right-box" *ngIf="((cmpView === 'Map Existing Companies') || (cmpView === 'Import Companies')) && addEditIntegration">
              <span class="small">
              <mat-form-field appearance="outline" class="w-100 wider-form-field">
                <mat-label>Select Company Type</mat-label>
                <mat-select [(ngModel)]="selectedComType" (selectionChange)="getIntegrationdata()" name="selectedComType">
                  <mat-option *ngFor="let type of integrationComType" [value]="type.id">{{ type.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              </span>
            </div>
          </div>
        </div>
        </mat-card-header>  
        <mat-card-content class="mt-2">
          <div *ngIf="!addEditIntegration">
            <app-s-table aria-label="Integration Mapping" role="table" [sTableOptions]="inteTableOptions"
              (sortCallback)="intesortCall($event)" (filterCallback)="integmappingfilterCall($event)"
              (actionCallback)="integmappingactionCall($event)" (pageCallback)="integmappingpageCall($event)"
              (refreshCallback)="getIntegrationMapping()" (addCallback)="integmappingaddTableData()"
              (timerCallback)="integmappingtimerCallData()"></app-s-table>
          </div>
          <ng-container *ngIf="addEditIntegration">
            <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
              (click)="addEditIntegration = false;">
              <mat-icon>close</mat-icon>
            </button>
            <h3>New Company Mapping</h3>
            <mat-card *ngIf="cmpView === 'Type'" class="mat-elevation-z0 p-0">
              <mat-card-header>
                <mat-card-title class="fw2"> Would you like to</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <mat-radio-group [(ngModel)]="cmpMapping.selectedType" class="d-flex flex-row align-items-center pt-3">
                  <mat-radio-button class="pr-4" [disabled]="currentCompany && currentCompany._id"
                    value="Import Companies">Import Companies from {{currentIntegration.title}}
                  </mat-radio-button>
                  <mat-radio-button [disabled]="onboarding" class="pr-4" value="Map Existing Companies">
                    Map Existing Company to a {{currentIntegration.title}} Company
                  </mat-radio-button>
                </mat-radio-group>
              </mat-card-content>
              <mat-card-footer class="p-3 d-flex align-items-end mr-1">
                <button class="mr-2" (click)="addEditIntegration = false;" mat-raised-button="">Cancel</button>
                <button (click)="cmpView = cmpMapping.selectedType; getIntegrationdata();"
                  [disabled]="!cmpMapping.selectedType" color="primary" mat-raised-button="">Next</button>
              </mat-card-footer>
            </mat-card>
            <mat-card *ngIf="cmpView === 'Map Existing Companies'" class="mat-elevation-z0 p-0">
              <mat-card-content>
                <div class="panel panel-primary mat-card mat-elevation-z6 br-8 p-2 bg-white relative">
                  <div class="panel-body">
                    <blockquote>
                      <div class="noteicon">
                        <mat-icon role="img"
                          class="mat-icon notranslate ng-tns-c136-333 mr-2 text-primary mat-20 mat-icon-no-color ng-star-inserted"
                          aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="information-circle"
                          data-mat-icon-namespace="heroicons_solid"><svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor" fit="" height="100%" width="100%"
                            preserveAspectRatio="xMidYMid meet" focusable="false">
                            <path fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                              clip-rule="evenodd"></path>
                          </svg></mat-icon>
                        <div class="d-flex flex-column">
                          <div class="d-flex flex-column">
                            <ng-container *ngIf="currentIntegration?.enableConfiguration">
                              <span *ngFor="let note of notes; let i=index"> {{note}} </span>
                            </ng-container>
                            <ng-container *ngIf="!currentIntegration?.enableConfiguration">
                              <span *ngFor="let note of defaultNotes; let i=index"> {{note}} </span>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </blockquote>
                    <div class="panel panel-primary mat-card mat-elevation-z6 br-8 p-2 mt-2 bg-white relative">
                      <div class="panel-body">
                        <p class="badge badge-info tbg-info text-primary py-2 px-3 fs--8 mb-2">
                          Map Existing Company to {{currentIntegration.title}} Company
                        </p>
                        <div class="d-flex align-items-center mt-1 w-100">
                          <span class="w-330px mr-3">
                            <div class="d-flex align-items-center">
                              <span class="w-100">
                                <mat-form-field class="w-100" appearance="fill">
                                  <mat-label>Existing Company</mat-label>
                                  <mat-select #companySelect panelClass="myPanelClass" disableOptionCentering
                                    class="mySelectClass mat-small round-select" id="ExistingCompanySelect"
                                    placeholder="Search existing Companies" (openedChange)="closeCurrentCompany($event)"
                                    [formControl]="companyCtrl" (selectionChange)="updateCurrentCompany($event.value)">
                                    <mat-option>
                                      <ngx-mat-select-search [formControl]="companyFilterCtrl" id="SearchInput"
                                        [placeholderLabel]="searchTxt"
                                        [noEntriesFoundLabel]="'No matching company found'"
                                        [searching]="searching"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option [matTooltip]="company.name"
                                      *ngFor="let company of filteredCompanies | async" [id]="company.name"
                                      [value]="company._id">
                                      {{company.name}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </span>
                            </div>
                          </span>
                          <span class="w-330px mr-3">
                            <div class="d-flex align-items-center">
                              <span class="w-100">
                                <mat-form-field class="w-100" appearance="fill"
                                  *ngIf="companyList && companyList.length">
                                  <mat-label>{{currentIntegration.title}} Company</mat-label>
                                  <mat-select [multiple]="false" disableOptionCentering panelClass="myPanelClass"
                                    class="mySelectClass" id="ConnectwiseSelect"
                                    (selectionChange)=" (currentIntegration.importSites) ? getIntegrationSites() : null"
                                    placeholder="Search {{currentIntegration.title}} Companies"
                                    [(ngModel)]="cmpMapping.destinationCompany">
                                    <mat-option *ngFor="let item of companyList" [value]="item">   
                                      <ng-container  *ngIf="currentIntegration.name !== 'Autotask'">
                                        <span *ngFor="let field of companyActionParams.DisplayFields" class="pr-2">
                                          {{item[field]}}
                                        </span>
                                      </ng-container>
                                      <ng-container *ngIf="currentIntegration.name === 'Autotask'">
                                        <span class="pr-2">
                                          {{item.companyName}}
                                        </span>
                                      </ng-container>
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </span>
                            </div>
                          </span>
                          <span class="mr-3">
                            <button [matMenuTriggerFor]="menuRef" #menuRefMenuTrigger="matMenuTrigger"
                              mat-icon-button="" matTooltip="Search Company">
                              <mat-icon class="mat-24">search</mat-icon></button>
                            <mat-menu #menuRef="matMenu" [hasBackdrop]="false">
                              <div class="flex-column align-items-center p-2 w-100" (click)="$event.stopPropagation();"
                                (keydown)="$event.stopPropagation()">
                                <span class="w-100">
                                  <app-dynamic-form [isCloseBtn]="false" [isSaveBtn]="false"
                                    *ngIf="companyParams && companyFormElements && companyFormElements.length"
                                    [listOfFormElements]="companyFormElements" [Valuesoutput]="companyParams">
                                  </app-dynamic-form>
                                </span>
                                <span>
                                  <button (click)="getIntegrationCompanies(); menuRefMenuTrigger.closeMenu()"
                                    mat-stroked-button="" type="button" color="primary">Get Companies</button>
                                </span>
                              </div>
                            </mat-menu>
                          </span>
                        </div>
                        <ng-container *ngIf="currentIntegration.importSites">
                          <p class="badge badge-info tbg-info text-primary py-2 px-3 fs--8 mb-2">
                            Map {{currentIntegration.title}} Site
                          </p>
                          <div class="d-flex align-items-center mt-1 w-100">
                            <span class="w-330px mr-3">
                              <div class="d-flex align-items-center">
                                <span class="w-100">
                                  <mat-form-field class="w-100" appearance="fill" *ngIf="siteList && siteList.length">
                                    <mat-label>{{currentIntegration.title}} Site</mat-label>
                                    <mat-select [multiple]="false" disableOptionCentering panelClass="myPanelClass"
                                      class="mySelectClass" id="ConnectwiseSelectSite"
                                      placeholder="Search {{currentIntegration.title}} Sites"
                                      [(ngModel)]="cmpMapping.site">
                                      <mat-option *ngFor="let item of siteList" [id]="item.identifier" [value]="item">
                                        <span *ngFor="let field of siteActionParams.DisplayFields" class="pr-2">
                                          {{item[field]}}
                                        </span>
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </span>
                              </div>
                            </span>
                            <span class="mr-3">
                              <button [matMenuTriggerFor]="menuSiteRef" #menuRefSiteMenuTrigger="matMenuTrigger"
                                mat-icon-button="" matTooltip="Search Site">
                                <mat-icon class="mat-24">search</mat-icon></button>
                              <mat-menu #menuSiteRef="matMenu" [hasBackdrop]="false">
                                <div class="flex-column align-items-center p-2 w-100"
                                  (click)="$event.stopPropagation();" (keydown)="$event.stopPropagation()">
                                  <span class="w-100">
                                    <app-dynamic-form [isCloseBtn]="false" [isSaveBtn]="false"
                                      *ngIf="siteParams && siteFormElements && siteFormElements.length"
                                      [listOfFormElements]="siteFormElements" [Valuesoutput]="siteParams">
                                    </app-dynamic-form>
                                  </span>
                                  <span>
                                    <button (click)="getIntegrationSites(); menuRefSiteMenuTrigger.closeMenu()"
                                      mat-stroked-button="" type="button" color="primary">Get Site</button>
                                  </span>
                                </div>
                              </mat-menu>
                            </span>
                          </div>
                        </ng-container>

                        <span class="d-flex align-items-center w-100 mt-2"
                          *ngIf="currentIntegration?.enableConfiguration">
                          <span class="">
                            <mat-slide-toggle [(ngModel)]="cmpMapping.configuration" name="Configuration"
                              matTooltip="Enable Configuration" (change)="openConfirmationDialog()">
                            </mat-slide-toggle>
                            <span class="ml-2 fs--9">Enable Configuration</span>
                          </span>
                        </span>
                        <p class="badge badge-info tbg-info text-primary py-2 px-3 mb-2 fs--8 mt-20">
                          Create Ticketing
                        </p>
                        <div class="d-flex align-items-center w-100">
                          <span class="w-330px">
                            <mat-checkbox class="ast-check" [(ngModel)]="cmpMapping.no_notification" name="default"
                              color="primary">Create Ticket</mat-checkbox>
                          </span>
                        </div>
                        <div class="d-flex flex-row align-items-center">
                          <mat-form-field class="w-330px mr-3">
                            <mat-label>Event Set</mat-label>
                            <mat-select #eventSelect disableOptionCentering panelClass="myPanelClass"
                              class="mySelectClass" [multiple]="false" [placeholder]="'Select event set'"
                              name="reportApp" (selectionChange)="eveHash[0]=$event.value" [(ngModel)]="eveHash[0]"
                              [formControl]="alertCtrl">
                              <mat-option>
                                <ngx-mat-select-search [formControl]="alertFilterCtrl"
                                  [placeholderLabel]="'Search event set'"
                                  [noEntriesFoundLabel]="'No matching data found'" [searching]="searching">
                                </ngx-mat-select-search>
                              </mat-option>
                              <mat-option [matTooltip]="opt.name"
                                *ngFor="let opt of alertRuleList | sortBy:'asc':'name' | search:alertFilterCtrl.value"
                                [value]="opt._id">
                                {{opt.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field class="w-330px mr-3">
                            <mat-label>Integration Profile</mat-label>
                            <mat-select #integSelect panelClass="myPanelClass" class="mySelectClass"
                              disableOptionCentering [multiple]="false" [placeholder]="'Select integration profile'"
                              name="reportApp" (selectionChange)="integHash[0]=$event.value" [(ngModel)]="integHash[0]"
                              [formControl]="inteRuleCtrl">
                              <mat-option>
                                <ngx-mat-select-search [formControl]="inteRuleFilterCtrl"
                                  [placeholderLabel]="'Search integration profile'"
                                  [noEntriesFoundLabel]="'No matching data found'" [searching]="searching">
                                </ngx-mat-select-search>
                              </mat-option>
                              <mat-option [matTooltip]="opt.name"
                                *ngFor="let opt of intergrationRuleList | sortBy:'asc':'name' | search:inteRuleFilterCtrl.value"
                                [value]="opt._id">
                                {{opt.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-icon class="mat-24 pointer col-2 mt-3" matTooltip="Add event set and integration mapping"
                            (click)="addExisingCmpEveInteg(0)">add
                          </mat-icon>
                        </div>
                        <div class="d-flex flex-row align-items-center mt-2">
                          <table class="infoTable table w-70">
                            <thead>
                              <tr>
                                <th>Event Set</th>
                                <th>Integration Profile</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container
                                *ngIf="cmpMapping.notificationProfiles && cmpMapping.notificationProfiles.length">
                                <tr *ngFor="let ei of cmpMapping.notificationProfiles; let ie = index;">
                                  <td>{{integrationActionService.alertRuleListHash[ei.eventSettingsId].name}}</td>
                                  <td>
                                    {{integrationActionService.integrationRuleListHash[ei.integrationSettingsId].name}}
                                  </td>
                                  <td><mat-icon class="mat-24 pointer"
                                      matTooltip="Add event set and integration mapping"
                                      (click)="cmpMapping.notificationProfiles.splice(ie, 1)">delete</mat-icon></td>
                                </tr>
                              </ng-container>
                              <tr *ngIf="!cmpMapping.notificationProfiles || !cmpMapping.notificationProfiles.length">
                                No record added.
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <span class="ml-3 pb-4">
                      <button (click)="addToMapList()" class="float-right" mat-stroked-button=""
                        matTooltip="Add to mapping list" type="button" color="primary">Add</button>
                    </span>
                  </div>
                </div>
                <div class="m-h-400 overflow-auto"
                  *ngIf="cmpMapping.mapCompanyList && cmpMapping.mapCompanyList.length">
                  <table class="infoTable w-100">
                    <thead>
                      <tr>
                        <th>Source Company</th>
                        <th>Destination Company</th>
                        <th *ngIf="currentIntegration.importSites">Site Name</th>
                        <th>Event Set - Integration Profile</th>
                        <th>Create Ticket</th>
                        <th class="mr-2" *ngIf="currentIntegration?.enableConfiguration">Enable
                          Configuration</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="cmpMapping.mapCompanyList && cmpMapping.mapCompanyList.length">
                        <tr *ngFor="let cmp of cmpMapping.mapCompanyList; let i = index">
                          <td>
                            {{cmp.source.name}}
                          </td>
                          <td>
                            {{cmp.destination[companyActionParams.DisplayFields[0]]}}
                          </td>
                          <td *ngIf="currentIntegration.importSites">
                            {{cmp.site[siteActionParams.DisplayFields[0]]}}
                          </td>
                          <td>
                            <mat-list dense>
                              <mat-list-item *ngFor="let np of cmp.notificationProfiles;">
                                <span>{{integrationActionService.alertRuleListHash[np.eventSettingsId].name}}</span>
                                <span class="mx-2">-</span>
                                <span>{{integrationActionService.integrationRuleListHash[np.integrationSettingsId].name}}</span>
                              </mat-list-item>
                            </mat-list>
                          </td>
                          <td class="mr-2">
                            <strong *ngIf="cmp.no_notification" class="badge badge-success text-white px-2 py-1">
                              Yes
                            </strong>
                            <strong *ngIf="!cmp.no_notification" class="badge badge-danger text-white px-2 py-1">
                              No
                            </strong>
                          </td>
                          <td class="mr-2" *ngIf="currentIntegration?.enableConfiguration">
                            <strong *ngIf="cmp.configuration" class="badge badge-success text-white px-2 py-1">
                              Yes
                            </strong>
                            <strong *ngIf="!cmp.configuration" class="badge badge-danger text-white px-2 py-1">
                              No
                            </strong>
                          </td>
                          <td>
                            <button mat-icon-button="" (click)="cmpMapping.mapCompanyList.splice(i, 1)">
                              <mat-icon class="mat-24">delete</mat-icon>
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </mat-card-content>
              <mat-card-footer class="p-3 d-flex align-items-center mr-1">
                <button class="mr-2"
                  (click)="cmpView = 'Type'; cmpMapping.selectedCompanies = []; cmpMapping.mapCompanyList = []"
                  mat-stroked-button="">Back</button>
                <button [disabled]="!cmpMapping.mapCompanyList || !cmpMapping.mapCompanyList.length || isLoading"
                  (click)="mapSelectedCompanies()" mat-raised-button="" color="primary">Finish</button>
              </mat-card-footer>
            </mat-card>
            <mat-card *ngIf="cmpView === 'Import Companies'" class="mat-elevation-z0 p-0">
              <mat-card-header>
                <mat-card-title class="fw2">{{currentIntegration.title}} Companies</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <blockquote>
                  <div class="noteicon">
                    <mat-icon role="img"
                      class="mat-icon notranslate ng-tns-c136-333 mr-2 text-primary mat-20 mat-icon-no-color ng-star-inserted"
                      aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="information-circle"
                      data-mat-icon-namespace="heroicons_solid"><svg xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor" fit="" height="100%" width="100%"
                        preserveAspectRatio="xMidYMid meet" focusable="false">
                        <path fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"></path>
                      </svg></mat-icon>
                    <div class="d-flex flex-column">
                      <ng-container *ngIf="currentIntegration?.enableConfiguration">
                        <span *ngFor="let note of notes; let i=index"> {{note}} </span>
                      </ng-container>
                      <ng-container *ngIf="!currentIntegration?.enableConfiguration">
                        <span *ngFor="let note of defaultNotes; let i=index"> {{note}} </span>
                      </ng-container>
                    </div>
                  </div>
                </blockquote>
                <div class="d-flex align-items-top">
                  <div class="w-70p p-2 border-right">
                    <mat-form-field class="w-100" appearance="outline" >
                      <mat-label>Select Companies</mat-label>
                      <mat-select [multiple]="true" (ngModelChange)="updateSelected($event)"
                        placeholder="Select Companies" [(ngModel)]="selectedCompanyList">
                        <mat-option *ngFor="let item of companyList" [value]="item">
                          <ng-container  *ngIf="currentIntegration.name !== 'Autotask'">
                            <span *ngFor="let field of companyActionParams.DisplayFields" class="pr-2">
                              {{item[field]}}
                            </span>
                          </ng-container>
                          <ng-container *ngIf="currentIntegration.name === 'Autotask'">
                            <span class="pr-2">
                              {{item.name}}
                            </span>
                          </ng-container>
                          
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <ng-container class="w-100" *ngIf="cmpMapping.selectedCompanies.length">
                      <div class="panel panel-primary mat-card mat-elevation-z6 br-8 p-2 bg-white relative">
                        <div class="panel-body">
                          <mat-card class="w-100 mt-2"
                            *ngFor="let cmp of cmpMapping.selectedCompanies; let i = index; trackBy:loaderService.trackByFn">
                            <mat-card-header>
                              <mat-icon class="mat-24 pointer r-1p position-absolute" matTooltip="Remove Company"
                                (click)="removeSelected(i)">delete</mat-icon>
                              <mat-card-title>{{cmp.name}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <ng-container *ngIf="currentIntegration.importSites">
                                <p class="badge badge-info tbg-info text-primary py-2 px-3 fs--8 mb-2">
                                  Map {{currentIntegration.title}} Site
                                </p>
                                <div class="d-flex align-items-center mt-1 w-100">
                                  <span class="w-330px mr-3">
                                    <div class="d-flex align-items-center">
                                      <span class="w-100">
                                        <mat-form-field class="w-100" appearance="fill"
                                          *ngIf="cmp.siteList && cmp.siteList.length">
                                          <mat-label>{{currentIntegration.title}} Site</mat-label>
                                          <mat-select [multiple]="false" disableOptionCentering
                                            panelClass="myPanelClass" class="mySelectClass"
                                            id="ConnectwiseSelectSite{{i}}"
                                            placeholder="Search {{currentIntegration.title}} Sites"
                                            [(ngModel)]="cmp.site">
                                            <mat-option *ngFor="let item of cmp.siteList" [id]="item.identifier"
                                              [value]="item">
                                              <span *ngFor="let field of siteActionParams.DisplayFields" class="pr-2">
                                                {{item[field]}}-{{item.id}}
                                              </span>
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </span>
                                    </div>
                                  </span>
                                  <span class="mr-3">
                                    <button [matMenuTriggerFor]="menuSiteRef" #menuRefSiteMenuTrigger="matMenuTrigger"
                                      mat-icon-button="" matTooltip="Search Site">
                                      <mat-icon class="mat-24">search</mat-icon></button>
                                    <mat-menu #menuSiteRef="matMenu" [hasBackdrop]="false">
                                      <div class="flex-column align-items-center p-2 w-100"
                                        (click)="$event.stopPropagation();" (keydown)="$event.stopPropagation()">
                                        <span class="w-100">
                                          <app-dynamic-form [isCloseBtn]="false" [isSaveBtn]="false"
                                            *ngIf="cmp.siteParams && cmp.siteFormElements && cmp.siteFormElements.length"
                                            [listOfFormElements]="cmp.siteFormElements" [Valuesoutput]="cmp.siteParams">
                                          </app-dynamic-form>
                                        </span>
                                        <span>
                                          <button
                                            (click)="getImportIntegrationSites(cmp, i); menuRefSiteMenuTrigger.closeMenu()"
                                            mat-stroked-button="" type="button" color="primary">Get Site</button>
                                        </span>
                                      </div>
                                    </mat-menu>
                                  </span>
                                </div>
                              </ng-container>
                              <div class="d-flex flex-row mt-2">
                                <span class="col-5">
                                  <mat-checkbox class="ast-check" [(ngModel)]="cmp.no_notification" name="default{{i}}"
                                    color="primary">Create Ticket</mat-checkbox>
                                </span>
                                <span class="col-5" *ngIf="currentIntegration?.enableConfiguration">
                                  <mat-slide-toggle class="" [(ngModel)]="cmp.configuration" name="Configuration{{i}}"
                                    matTooltip="Enable Configuration"><span class="mr-2 fs--9">Enable
                                      Configuration</span>
                                  </mat-slide-toggle>
                                </span>
                              </div>
                              <div class="d-flex flex-row mt-3 align-items-center">
                                <mat-form-field class="col-5">
                                  <mat-label>Event Set</mat-label>
                                  <mat-select #eventSelect disableOptionCentering panelClass="myPanelClass"
                                    class="mySelectClass" [multiple]="false" [placeholder]="'Select event set'"
                                    name="reportApp" (selectionChange)="eveHash[i]=$event.value"
                                    [(ngModel)]="eveHash[i]" [formControl]="alertCtrl">
                                    <mat-option>
                                      <ngx-mat-select-search [formControl]="alertFilterCtrl"
                                        [placeholderLabel]="'Search event set'"
                                        [noEntriesFoundLabel]="'No matching data found'" [searching]="searching">
                                      </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option [matTooltip]="opt.name"
                                      *ngFor="let opt of alertRuleList | sortBy:'asc':'name' | search:alertFilterCtrl.value"
                                      [value]="opt._id">
                                      {{opt.name}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-5">
                                  <mat-label>Integration Profile</mat-label>
                                  <mat-select #integSelect panelClass="myPanelClass" class="mySelectClass"
                                    disableOptionCentering [multiple]="false"
                                    [placeholder]="'Select integration profile'" name="reportApp"
                                    (selectionChange)="integHash[i]=$event.value" [(ngModel)]="integHash[i]"
                                    [formControl]="inteRuleCtrl">
                                    <mat-option>
                                      <ngx-mat-select-search [formControl]="inteRuleFilterCtrl"
                                        [placeholderLabel]="'Search integration profile'"
                                        [noEntriesFoundLabel]="'No matching data found'" [searching]="searching">
                                      </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option [matTooltip]="opt.name"
                                      *ngFor="let opt of intergrationRuleList | sortBy:'asc':'name' | search:inteRuleFilterCtrl.value"
                                      [value]="opt._id">
                                      {{opt.name}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <span>
                                  <button (click)="addEveIntegToCompany(i)" mat-stroked-button=""
                                    matTooltip="Add event set and integration mapping" type="button"
                                    color="primary">Add</button>
                                </span>
                              </div>
                              <div class="d-flex flex-row align-items-center mt-2">
                                <table class="infoTable table w-70p">
                                  <thead>
                                    <tr>
                                      <th>Event Set</th>
                                      <th>Integration</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <ng-container *ngIf="cmp.notificationProfiles && cmp.notificationProfiles.length">
                                      <tr *ngFor="let ei of cmp.notificationProfiles; let ie = index;">
                                        <td>{{integrationActionService.alertRuleListHash[ei.eventSettingsId].name}}</td>
                                        <td>
                                          {{integrationActionService.integrationRuleListHash[ei.integrationSettingsId].name}}
                                        </td>
                                        <td><mat-icon class="mat-24 pointer"
                                            matTooltip="Add event set and integration mapping"
                                            (click)="removeItem(i, ie)">delete</mat-icon></td>
                                      </tr>
                                    </ng-container>
                                    <tr *ngIf="!cmp.notificationProfiles || !cmp.notificationProfiles.length">
                                      No record added.
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                      </div>
                    </ng-container>
                    <span class="fw2 py-1 float-right" *ngIf="cmpMapping.selectedCompanies.length > 5">
                      Total selected companies - {{cmpMapping.selectedCompanies.length}}
                    </span>
                  </div>
                  <div class="flex-column align-items-center p-2 w-30p">
                    <span class="w-100">
                      <app-dynamic-form [isCloseBtn]="false" [isSaveBtn]="false"
                        *ngIf="companyParams && companyFormElements && companyFormElements.length"
                        [listOfFormElements]="companyFormElements" [Valuesoutput]="companyParams">
                      </app-dynamic-form>
                    </span>
                    <span>
                      <button (click)="getIntegrationCompanies()" mat-stroked-button="" type="button"
                        color="primary">Get
                        Companies</button>
                    </span>
                  </div>
                </div>
              </mat-card-content>
              <mat-card-footer class="p-3 d-flex align-items-center mr-1">
                <button class="mr-2" (click)="cmpView = 'Type'; cmpMapping.selectedCompanies = []"
                  mat-stroked-button="">Back</button>
                <button [disabled]="!cmpMapping.selectedCompanies || !cmpMapping.selectedCompanies.length "
                  (click)="showSummary();" mat-raised-button="" color="primary">Next</button>
              </mat-card-footer>
            </mat-card>
            <mat-card *ngIf="cmpView === 'Summary'" class="mat-elevation-z0 p-0">
              <mat-card-header>
                <mat-card-title class="fw2">
                  Selected {{currentIntegration.title}} companies ({{cmpMapping.selectedCompanies.length}})
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="m-h-400 overflow-auto">
                  <table class="infoTable w-100">
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th *ngIf="currentIntegration.importSites">Site Name</th>
                        <th *ngIf="currentIntegration.importSites">Site Id</th>
                        <th>Event Set</th>
                        <th>Integration Profile</th>
                        <th>Create Ticket</th>
                        <th class="mr-2" *ngIf="currentIntegration?.enableConfiguration">Enable
                          Configuration</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container>
                        <tr *ngFor="let cmp of cmpMapping.selectedCompanies; let i = index">
                          <td>
                            {{cmp[companyActionParams.DisplayFields[0]]}}
                          </td>
                          <td *ngIf="currentIntegration.importSites">
                            {{cmp.site[siteActionParams.DisplayFields[0]]}}
                          </td>
                          <td *ngIf="currentIntegration.importSites">
                            {{cmp.site.id}}
                          </td>
                          <td>
                            <div [innerHTML]="cmp.eventName | safeHTML"></div>
                          </td>
                          <td>
                            <div [innerHTML]="cmp.ruleName | safeHTML"></div>
                          </td>
                          <td class="mr-2">
                            <strong *ngIf="cmp.no_notification" class="badge badge-success text-white px-2 py-1">
                              Yes
                            </strong>
                            <strong *ngIf="!cmp.no_notification" class="badge badge-danger text-white px-2 py-1">
                              No
                            </strong>
                          </td>
                          <td class="mr-2" *ngIf="currentIntegration?.enableConfiguration">
                            <strong *ngIf="cmp.configuration" class="badge badge-success text-white px-2 py-1">
                              Yes
                            </strong>
                            <strong *ngIf="!cmp.configuration" class="badge badge-danger text-white px-2 py-1">
                              No
                            </strong>
                          </td>
                          <td>
                            <button mat-icon-button="" (click)="cmpMapping.selectedCompanies.splice(i, 1)">
                              <mat-icon class="mat-24">delete</mat-icon>
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
                <p class="mt-3 fs1 fw2">Click <span *ngIf="!onboarding">finish</span>
                  <span *ngIf="onboarding">create</span> to import all the selected {{currentIntegration.title}}
                  companies
                </p>
              </mat-card-content>
              <mat-card-footer class="p-3 d-flex align-items-center mr-1">
                <button class="mr-2" (click)="cmpView = 'Import Companies';" mat-stroked-button="">Back</button>
                <button
                  [disabled]="!cmpMapping.selectedCompanies || !cmpMapping.selectedCompanies.length || disableCreate"
                  (click)="importSelectedCompanies()" mat-raised-button="" color="primary">
                  <span *ngIf="!onboarding">Finish</span>
                  <span *ngIf="onboarding">Create</span>
                </button>
              </mat-card-footer>
            </mat-card>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
    <mat-sidenav #snav [mode]="'over'" fixedTopGap="56" position="end" class="w-70p z-index-150">
      <mat-card class="mat-elevation-z0 w-100" *ngIf="eView === 'edit'">
        <button class="position-absolute r-1p mt--10" mat-icon-button (click)="snav.close()"> <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title>Update Company Mapping</mat-card-title>
          <mat-card-subtitle class="mt-3">
            <span class="fs1 fw2">Existing Company Name : <strong>{{cmpMapping.existingCompanyName}}</strong> </span>
            <div class="spacer"></div>
            <span class="fs1 fw2">Company Name : <strong>{{cmpMapping.companyName}}</strong> </span>
            <ng-container *ngIf="currentIntegration.importSites">
              <div class="spacer"></div>
              <span class="fs1 fw2">Site Name : <strong>{{cmpMapping.siteName}}</strong> </span>
            </ng-container>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <form #updatemap="ngForm" name="credentials" autocomplete="off">
            <div class="panel panel-primary mat-card mat-elevation-z6 br-8 p-2 mt-2 bg-white relative">
              <div class="panel-body">
                <span class="w-100 mt-2" *ngIf="currentIntegration?.enableConfiguration">
                  <mat-slide-toggle class="" [(ngModel)]="cmpMapping.configuration" name="Configuration"
                    matTooltip="Enable Configuration" (change)="openConfirmationDialog()"><span class="mr-2">Enable
                      Configuration</span>
                  </mat-slide-toggle>
                </span>
                <span>
                  <mat-checkbox class="w-100 mt-3" [(ngModel)]="cmpMapping.no_notification" name="default"
                    color="primary">Create Ticket</mat-checkbox>
                </span>
                <div class="d-flex flex-row align-items-center mt-2">
                  <mat-form-field class="w-330px mr-3">
                    <mat-label>Event Set</mat-label>
                    <mat-select #eventSelect disableOptionCentering panelClass="myPanelClass" class="mySelectClass"
                      [multiple]="false" [placeholder]="'Select event set'" name="reportApp"
                      (selectionChange)="eveHash[0]=$event.value" [(ngModel)]="eveHash[0]" [formControl]="alertCtrl">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="alertFilterCtrl" [placeholderLabel]="'Search event set'"
                          [noEntriesFoundLabel]="'No matching data found'" [searching]="searching">
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option [matTooltip]="opt.name"
                        *ngFor="let opt of alertRuleList | sortBy:'asc':'name' | search:alertFilterCtrl.value"
                        [value]="opt._id">
                        {{opt.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-330px mr-3">
                    <mat-label>Integration Profile</mat-label>
                    <mat-select #integSelect panelClass="myPanelClass" class="mySelectClass" disableOptionCentering
                      [multiple]="false" [placeholder]="'Select integration profile'" name="reportApp"
                      (selectionChange)="integHash[0]=$event.value" [(ngModel)]="integHash[0]"
                      [formControl]="inteRuleCtrl">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="inteRuleFilterCtrl"
                          [placeholderLabel]="'Search integration profile'"
                          [noEntriesFoundLabel]="'No matching data found'" [searching]="searching">
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option [matTooltip]="opt.name"
                        *ngFor="let opt of intergrationRuleList | sortBy:'asc':'name' | search:inteRuleFilterCtrl.value"
                        [value]="opt._id">
                        {{opt.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span>
                    <button (click)="addExisingCmpEveInteg(0)" mat-stroked-button=""
                      matTooltip="Add event set and integration mapping" type="button" color="primary">Add</button>
                  </span>
                </div>
                <div class="d-flex flex-row align-items-center mt-2">
                  <table class="infoTable table">
                    <thead>
                      <tr>
                        <th>Event Set</th>
                        <th>Integration Profile</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="cmpMapping.notificationProfiles && cmpMapping.notificationProfiles.length">
                        <tr *ngFor="let ei of cmpMapping.notificationProfiles; let ie = index;">
                          <td>{{integrationActionService.alertRuleListHash[ei.eventSettingsId].name}}</td>
                          <td>{{integrationActionService.integrationRuleListHash[ei.integrationSettingsId].name}}</td>
                          <td><mat-icon class="mat-24 pointer" matTooltip="Add event set and integration mapping"
                              (click)="cmpMapping.notificationProfiles.splice(ie, 1)">delete</mat-icon></td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="!cmpMapping.notificationProfiles || !cmpMapping.notificationProfiles.length">No record
                        added.</tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div mat-dialog-actions class="mt-2">
              <button type="button" [disabled]="!updatemap.form.valid" mat-raised-button color="primary" class="mr-2"
                id="usrSavebtn" (click)="updateCompanyMapping()">Update
              </button>
              <button mat-raised-button (click)="snav.close()">Cancel</button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
      <mat-card class="mat-elevation-z0 w-100" *ngIf="eView === 'copy'">
        <button class="position-absolute r-1p mt--10" mat-icon-button (click)="snav.close()"> <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title>Copy Settings to other Company Mapping</mat-card-title>
          <mat-card-subtitle class="mt-3">
            <span class="fs1 fw2">Event Set : <strong>{{copyEvents.eventSet}}</strong> </span>
            <div class="spacer"></div>
            <span class="fs1 fw2">Integration Profile : <strong>{{copyEvents.integrationProfile}}</strong> </span>
            <div class="spacer"></div>
            <span class="fs1 fw2">Create Ticket :
              <img class="imageWidth ng-star-inserted" *ngIf="!copyEvents.no_notification"
                src="/assets/images/auditLogins/tick.svg" alt="Create Ticket">
              <img class="imageWidth ng-star-inserted" *ngIf="copyEvents.no_notification"
                src="/assets/images/auditLogins/cross.svg" alt="Create Ticket">
            </span>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <form #copymap="ngForm" name="copyevent" autocomplete="off">
            <mat-form-field class="mb-2 w-100 mr-2" appearance="outline">
              <mat-label>Company Mapping</mat-label>
              <mat-select #copyCompSelect [multiple]="true" class="" placeholder="Select company mapping"
                name="copyeventname"
                (selectionChange)="copyEvents.companyMappingId = ($event.value.includes('*')) ? ['*'] : $event.value"
                [(ngModel)]="copyEvents.companyMappingId" [formControl]="copyCompCtrl">
                <mat-option>
                  <ngx-mat-select-search [formControl]="copyCompFilterCtrl"
                    [placeholderLabel]="'Search company mapping'" [noEntriesFoundLabel]="'No matching mapping found'"
                    [searching]="searching">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option [matTooltip]="company.name"
                  *ngFor="let company of copyCompanyMappingList | sortBy:'asc':'name' | search:copyCompFilterCtrl.value"
                  [value]="company">
                  {{company.sourceCompanyName}}-{{company.destCompanyName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div mat-dialog-actions class="mt-2">
              <button type="button" [disabled]="!copymap.form.valid" mat-raised-button color="primary" class="mr-2"
                id="usrSavebtn" (click)="copyCompanyMapping()">Update
              </button>
              <button mat-raised-button (click)="snav.close()">Cancel</button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>