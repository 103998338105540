import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CompanyService, NotificationRulesService, SchedulerService } from 'src/app/api/services';
import { ReportFiltersComponent } from 'src/app/shared/report-filters/report-filters.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BaseRequestService } from 'src/app/_services/base.service';
import { CommonService } from 'src/app/_services/common.services';
import { ConfirmDialogService } from 'src/app/_services/confirmdialog.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { ModalService } from 'src/app/_services/modal.service';
import { MyToastrService } from 'src/app/_services/toastr.service';

@Component({
  selector: 'app-patching-scheduler',
  templateUrl: './patching-scheduler.component.html',
  styleUrls: ['./patching-scheduler.component.scss']
})
export class PatchingSchedulerComponent implements OnInit {
  @Input() currentCompany: any;
  @Input() schedulerType: any;
  @ViewChild('companySelect', {static: true}) companySelect!: MatSelect;
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();

  @ViewChild('companySelect1', {static: true}) companySelect1!: MatSelect;
  public companyCtrl1: FormControl = new FormControl();
  public companyFilterCtrl1: FormControl = new FormControl();

  @ViewChild('includesSelect', {static: true}) includesSelect!: MatSelect;
  public includesCtrl: FormControl = new FormControl();
  public includesFilterCtrl: FormControl = new FormControl();

  @ViewChild('excludesSelect', {static: true}) excludesSelect!: MatSelect;
  public excludesCtrl: FormControl = new FormControl();
  public excludesFilterCtrl: FormControl = new FormControl();

  @ViewChild('includeTags', {static: true}) includeTags!: MatSelect;
  public includeTagCtrl: FormControl = new FormControl();
  public includeTagFilterCtrl: FormControl = new FormControl();

  @ViewChild('excludeTags', {static: true}) excludeTags!: MatSelect;
  public excludeTagCtrl: FormControl = new FormControl();
  public excludeTagFilterCtrl: FormControl = new FormControl();

  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searching = false;
  protected onDestroySearch = new Subject<void>();
  @Input('companyName') companyName: string;
  @ViewChild('filter') filter: ReportFiltersComponent;

  company: any;
  companies: any;
  companyHash: any = {};
  allComp: any;
  selectedIndex = 0;
  patchTableOptions: any = [];
  patchcurrentPage = 0;
  patchfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  patchData: any = [];
  addEditPatch = false;
  notificationRule: any = [];
  integrations: any = [];
  iHash: any = {};
  integrationMappingHash: any = {};
  mappedIntegrations: any = [];
  selectedIntegrations: any = [];
  integrationsAvailable = false;
  selectedIntegrationsCredsHash: any = {};
  inputActionData: any = {};
  step = 0;
  schList: any = [];
  days: any = [];
  weekl: any = [];
  hourl: any = [];
  monthList: any = [];
  daysList: any = [];
  hoursList: any = [];
  mintsList: any = [];
  daily = false;
  weekly = false;
  hourly = false;
  monthly = false;
  quarterly = false;
  yearly = false;
  asset = false;
  monList: any;
  hourList: any;
  minList: any;
  yearList: any;
  siteId: any = '';
  deviceData: any = [];
  site = false;
  daysListOpt: any = [];
  hourMintTime: any;
  hourMintTimeEnd: any;
  repeatList: any;
  scheDetailsObj: any = new SchedulerDetails();
  toppingsControl = new FormControl([]);
  Objectkeys = Object.keys;
  schedulerData: never[];
  applictionsHash: any = {};
  includeApplictions: any = [];
  excludeApplictions: any = [];
  allSchedList: any;
  tagList: any = [];
  constructor(public modalService: ModalService, public toast: MyToastrService, private companyService: CompanyService,
              private baseService: BaseRequestService, private aS: AuthenticationService,
              private loaderService: LoaderService, public confirmDialog: ConfirmDialogService,
              private notificationRulesService: NotificationRulesService, private schedulerService: SchedulerService,
              public commonService: CommonService) {
                this.patchTableOptions = {
                  columns: [
                    {
                      header: 'Is Active',
                      columnDef: 'isActive',
                      filter: '',
                      cell: '(element: any) => `${element.isActive}`',
                      order: 0,
                      visible: true,
                      isToolTip: false,
                      isToolTipCol: '',
                      hasMultiData: false,
                      class: '',
                      color: '',
                      isProgressCntrl: false,
                      isColoredCntrl: false,
                      colList: [],
                      isfaicon: false,
                      isAddingText: false,
                      addingText: '',
                      img: false,
                      imgPath: '',
                      isSort: true,
                      iscolumnSearch: false,
                      statusicon: true,
                      success: true,
                      failure: false,
                      successIconPath: 'assets/images/devices/tick.svg', successToolTip: 'True',
                      failureIconPath: 'assets/images/devices/cross.svg', failureToolTip: 'False',
                    },
                    {
                      header: 'Scheduler Name',
                      columnDef: 'name',
                      filter: '',
                      cell: '(element: any) => `${element.name}`',
                      order: 1,
                      visible: true,
                      isToolTip: false,
                      isToolTipCol: '',
                      hasMultiData: false,
                      class: '',
                      color: '',
                      isProgressCntrl: false,
                      isColoredCntrl: false,
                      colList: [],
                      isfaicon: false,
                      isAddingText: false,
                      addingText: '',
                      img: false,
                      imgPath: '',
                      isSort: true
                    },
                    {
                      header: 'Scheduler',
                      columnDef: 'scheduler',
                      filter: 'cameltohuman',
                      cell: '(element: any) => `${element.scheduler}`',
                      order: 2,
                      visible: true,
                      isToolTip: false,
                      isToolTipCol: '',
                      hasMultiData: false,
                      class: '',
                      color: '',
                      isProgressCntrl: false,
                      isColoredCntrl: false,
                      colList: [],
                      isfaicon: false,
                      isAddingText: false,
                      addingText: '',
                      img: false,
                      imgPath: '',
                      isSort: true
                    },
                    {
                      header: 'Start Time',
                      columnDef: 'time',
                      filter: '',
                      cell: '(element: any) => `${element.time}`',
                      order: 3,
                      visible: true,
                      isToolTip: false,
                      isToolTipCol: '',
                      hasMultiData: false,
                      class: '',
                      color: '',
                      isProgressCntrl: false,
                      isColoredCntrl: false,
                      colList: [],
                      isfaicon: false,
                      isAddingText: false,
                      addingText: '',
                      img: false,
                      imgPath: '',
                      isSort: false
                    },
                    {
                      header: 'End Time',
                      columnDef: 'end_time',
                      filter: '',
                      cell: '(element: any) => `${element.end_time}`',
                      order: 4,
                      visible: true,
                      isToolTip: false,
                      isToolTipCol: '',
                      hasMultiData: false,
                      class: '',
                      color: '',
                      isProgressCntrl: false,
                      isColoredCntrl: false,
                      colList: [],
                      isfaicon: false,
                      isAddingText: false,
                      addingText: '',
                      img: false,
                      imgPath: '',
                      isSort: false
                    },
                    {
                      header: 'Created',
                      columnDef: 'c',
                      filter: 'utcToLocale',
                      cell: '(element: any) => `${element.c}`',
                      order: 5,
                      visible: true,
                      isToolTip: false,
                      isToolTipCol: '',
                      hasMultiData: false,
                      class: '',
                      color: '',
                      isProgressCntrl: false,
                      isColoredCntrl: false,
                      colList: [],
                      isfaicon: false,
                      isAddingText: false,
                      addingText: '',
                      img: false,
                      imgPath: '',
                      isSort: false
                    },
                    {
                      header: 'Updated',
                      columnDef: 'u',
                      filter: 'utcToLocale',
                      cell: '(element: any) => `${element.u}`',
                      order: 6,
                      visible: true,
                      isToolTip: false,
                      isToolTipCol: '',
                      hasMultiData: false,
                      class: '',
                      color: '',
                      isProgressCntrl: false,
                      isColoredCntrl: false,
                      colList: [],
                      isfaicon: false,
                      isAddingText: false,
                      addingText: '',
                      img: false,
                      imgPath: '',
                      isSort: false
                    }
                    ],
                  sortOptions: {active: 'name', direction: 'asc'},
                  faClass: 'Scheduler',
                  _pageData: [],
                  tableOptions: {
                    id: 'patchSchedulers',
                    title: 'Auto Patch',
                    isServerSide: true, add: (this.aS.hasPermission('scheduler', 'create')),
                    selectText: 'schedulers(s)',
                    loading: true,
                    floatingFilter: true,
                    rowSelection: !this.currentCompany,
                    showAction: true,
                    actionMenuItems: [],
                    pagination: true,
                    pageOptions: [5, 10, 25, 100],
                    pageSize: 30,
                    search: true,
                    showhideList: true,
                    refreshData: true,
                    exportExcel: true,
                    saveData: true,
                    filterDownload: false
                  }
                };
                this.repeatList = [
                  {value: 'hourly', text: 'Hourly'},
                  {value: 'daily', text: 'Daily'},
                  {value: 'monthly', text: 'Monthly'},
                  {value: 'yearly', text: 'Yearly'},
                ];

                this.monthList = [
                  {value: '1', text: 'January'},
                  {value: '2', text: 'February'},
                  {value: '3', text: 'March'},
                  {value: '4', text: 'April'},
                  {value: '5', text: 'May'},
                  {value: '6', text: 'June'},
                  {value: '7', text: 'July'},
                  {value: '8', text: 'August'},
                  {value: '9', text: 'September'},
                  {value: '10', text: 'October'},
                  {value: '11', text: 'November'},
                  {value: '12', text: 'December'},
                ];

                this.schList = [
                  {value: 'every_day', text: 'Every Day'},
                  {value: 'weekly', text: 'Weekly'},
                  {value: 'days_in_a_month', text: 'Days In A Month'},
                  {value: 'yearly', text: 'Yearly'}
                ];

                this.days = [
                  {value: '0', text: 'Sunday'},
                  {value: '1', text: 'Monday'},
                  {value: '2', text: 'Tuesday'},
                  {value: '3', text: 'Wednesday'},
                  {value: '4', text: 'Thursday'},
                  {value: '5', text: 'Friday'},
                  {value: '6', text: 'Saturday'}
                ];
                this.weekl = [
                  {value: '1', text: '1st Week'},
                  {value: '2', text: '2nd Week'},
                  {value: '3', text: '3rd Week'},
                  {value: '4', text: '4th Week'}
                ];

                this.hourl = [];

                for (let index = 0; index < 60; index++) {
                  const valueOrText = index + 1;
                  if (index < 31) {
                    this.daysList.push({value: valueOrText, text: valueOrText});
                  }
                  if (index < 23) {
                    this.hoursList.push({value: index, text: index});
                  }
                  this.mintsList.push({value: index, text: index});
                }
                for ( let i = 1; i <= 23; i ++) {
                const hrData = (i === 1) ? {value: i, name: `Every ${i} hour`} : {value: i, name: `Every ${i} hours`};
                this.hourl.push(hrData);
              }
                if (this.aS.hasPermission('scheduler', 'update')){
                this.patchTableOptions.tableOptions.actionMenuItems.unshift({text: 'Edit', icon: 'edit', callback: 'editFn', isGlobal: false});
              }
                if (this.aS.hasPermission('scheduler', 'delete')) {
                this.patchTableOptions.tableOptions.actionMenuItems.push({text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true});
              }
  }

  removeCompany(company: any): void {
    const index = this.scheDetailsObj?.companyid?.indexOf(company);
    if (index >= 0) {
      this.scheDetailsObj?.companyid?.splice(index, 1);
    }
  }

  changeSchedulerType(value: any): void {
    this.scheDetailsObj.settings.hourly = '';
    this.scheDetailsObj.settings.months = [];
    this.scheDetailsObj.settings.days = [];
    this.scheDetailsObj.settings.mins = [];
    this.scheDetailsObj.settings.week = [];
    this.scheDetailsObj.settings.weekdays = [];
    this.hourMintTime = '00:01';
    this.hourMintTimeEnd = '23:59';
    this.switchSchView(value);
  }
  removedKeys(index: any, key: any): void {
    this.scheDetailsObj[key] = this.scheDetailsObj[key]?.filter((item: any, idx: any) => idx !== index);
  }
  editScheduler(element: any): void {
    this.scheDetailsObj = new SchedulerDetails();
    this.hourMintTime = '00:01';
    this.hourMintTimeEnd = '23:59';
    if (element.row.settings?.hour !== undefined && element.row.settings.hour[0] !== undefined &&
      element.row.settings.mins !== undefined && element.row.settings.mins[0] !== undefined) {
        if (JSON.stringify(element.row.settings.hour[0]).length < 2){
          element.row.settings.hour[0] = '0' + element.row.settings.hour[0];
        }
        if (JSON.stringify(element.row.settings.mins[0]).length < 2){
          element.row.settings.mins[0] = '0' + element.row.settings.mins[0];
        }
        this.hourMintTime = element.row.settings.hour[0] + ':' + element.row.settings.mins[0];
    }
    if (element.row.endTime?.hour !== undefined && element.row.endTime?.hour[0] !== undefined &&
      element.row.endTime?.mins !== undefined && element.row.endTime?.mins[0] !== undefined) {
        if (JSON.stringify(element.row.endTime.hour[0]).length < 2){
          element.row.endTime.hour[0] = '0' + element.row.endTime.hour[0];
        }
        if (JSON.stringify(element.row.endTime.mins[0]).length < 2){
          element.row.endTime.mins[0] = '0' + element.row.endTime.mins[0];
        }
        this.hourMintTimeEnd = element.row.endTime.hour[0] + ':' + element.row.endTime.mins[0];
    }
    this.scheDetailsObj = Object.assign({}, element.row);
    if (element.row.settings.hours && element.row.settings.hours[0]) {
      this.scheDetailsObj.settings.hourly = element.row.settings.hours[0];
    }
    if (element.row.isGlobal) {
      this.scheDetailsObj.agentid = ['*'];
      this.scheDetailsObj.companyid = ['*'];
    } else {
      this.scheDetailsObj.companyid = (element.row.companyRef) ? element.row.companyRef.map((x: any) => x.id) : [];
      this.scheDetailsObj.excludecompany = (element.row.excludecompany && element.row.excludecompany.length) ? element.row.excludecompany : [];
    }
    this.scheDetailsObj.c = (!element.row.c) ?  '2021-07-29T05:17:28.448967' : element.row.c;
    this.siteId = element.row.siteid;
    this.scheDetailsObj.siteName = element.row.siteName === '' ? 'All' : element.row.siteName;
    this.switchSchView(element.row.scheduler);
    this.scheDetailsObj.email = (this.scheDetailsObj.email && this.scheDetailsObj.email.length) ? this.scheDetailsObj.email : [''];
    this.scheDetailsObj.settings.week = (this.scheDetailsObj.settings.week) ? this.scheDetailsObj.settings.week.map(String) : [];
    this.scheDetailsObj.settings.weekdays = this.scheDetailsObj.settings.weekdays.map(String);
    this.scheDetailsObj.settings.months = this.scheDetailsObj.settings.months.map(String);
    if (this.scheDetailsObj.settings.days.length > 0) {
      this.scheDetailsObj.settings.days.map(String);
      this.daily = true;
    }
  }
  switchSchView(value: string): void {
    switch (value) {
      case 'every_day':
        this.daily = true;
        this.weekly = false;
        this.hourly = false;
        this.monthly = false;
        this.quarterly = false;
        this.yearly = false;
        break;
      case 'hourly':
        this.daily = false;
        this.weekly = false;
        this.hourly = true;
        this.monthly = false;
        this.quarterly = false;
        this.yearly = false;
        break;
      case 'weekly':
        this.weekly = true;
        this.daily = false;
        this.hourly = false;
        this.monthly = false;
        this.quarterly = false;
        this.yearly = false;
        break;
      case 'days_in_a_month':
        this.monthly = true;
        this.daily = false;
        this.hourly = false;
        this.weekly = false;
        this.quarterly = false;
        this.yearly = false;
        break;
      case 'quarterly':
        this.quarterly = true;
        this.daily = false;
        this.weekly = false;
        this.monthly = false;
        this.yearly = false;
        break;
      case 'yearly':
        this.yearly = true;
        this.daily = false;
        this.hourly = false;
        this.weekly = false;
        this.monthly = true;
        this.quarterly = false;
        break;
      default: {
        this.daily = false;
        this.hourly = false;
        this.weekly = false;
        this.monthly = false;
        this.quarterly = false;
        this.yearly = false;
        break;
      }
    }
  }

  MonthFinalVal(val: any): void {
    this.daily = false;
    this.scheDetailsObj.settings.days = [];
    //  console.log(val);
    val.forEach((element: any) => {
      this.scheDetailsObj.settings.days.push(element);
    });
    // this.scheDetailsObj.settings.days = Object.assign([], val);
    if (val.length > 0) {
      this.daily = true;
    }
    this.modalService.close('calPopup');

  }

  monthlySchedulerType(value: any): void {
    if (value) {
      this.monList = value;
      this.daily = true;
    }
  }

  yearlySchedulerType(value: any): void {
    if (value) {
      this.yearList = value;
      this.monthly = true;
    }
  }

  patchshowHideLoading(status: any): void {
    const data = Object.assign({}, this.patchTableOptions);
    this.patchTableOptions = {};
    this.patchTableOptions = data;
    this.patchTableOptions.tableOptions.loading = status;
  }

  patchactionCall(idata: any): void {
    const allowed = (!this.currentCompany) ? true : this.checkCurrentCompany(idata.row);
    if (idata.action.text === 'Edit') {
      if (allowed) {
        (!idata.row.includedApplication || !idata.row.includedApplication.length) ? idata.row.includedApplication = ['*'] : idata.row.includedApplication;
        this.addEditPatch = true;
        this.editScheduler(idata);
      } else {
        this.toast.sToast('error', 'No access!');
      }
    }
    if (idata.action.text === 'Delete') {
      if (allowed) {
        this.deleteSchedulerConfirmationDialog(idata.row);
      } else {
        this.toast.sToast('error', 'No access!');
      }
    }
  }

  checkCurrentCompany(data: any): boolean {
    return data.companyRef.filter((a: any) => a.id === this.currentCompany._id).length;
  }
  globalActionCall(data: any): void {
    this.deleteScheConfirmationDialogForAll(data);
  }

  deleteScheConfirmationDialogForAll(data: any): void {
    let cnt = 0;
    // let supportMsg = '';
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete selected schedulers?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(false);
        this.allSchedList = data.row;
        this.allSchedList.forEach((element: any) => {
          this.baseService.doRequest(`/api/scheduler/${element._id}`,
            'delete').subscribe((result: any) => {
            cnt++;
            if (result) {
              if (cnt === this.allSchedList.length) {
                this.toast.sToast('success', 'Scheduler removed successfully');
                this.getPatchingSchedulerData();
                this.loaderService.display(false);
              }
            } else {
              // supportMsg += element.scheduler + ', ';
              if (cnt === this.allSchedList.length) {
                this.toast.sToast('error', 'Scheduler Not Deleted');
                this.getPatchingSchedulerData();
                this.loaderService.display(false);
              }
            }
          }, (error: any) => {
            cnt++;
            // supportMsg += element.first_name + ', ';
            if (cnt === this.allSchedList.length) {
              this.toast.sToast('error', 'Scheduler Not Deleted');
              this.getPatchingSchedulerData();
              this.loaderService.display(false);
            }
          });
        });
      }
    });
  }
  deleteSchedulerConfirmationDialog(element: any): void {
    const titleName = 'Confirmation';
    const message = `Are you sure you want to delete this scheduler ${element.name}?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.baseService.doRequest(`/api/scheduler/${element._id}`, 'delete').subscribe((result: any) => {
          if (result) {
            this.toast.sToast('success', 'Schedulers removed successfully');
            this.addEditPatch = false;
            setTimeout( () => this.getPatchingSchedulerData(), 2000);
          } else {
            this.toast.sToast('error', result);
          }
        });
      }
    });
  }
  patchfilterCall(event: any): void {
    console.log(event);
    this.patchshowHideLoading(true);
    setTimeout(() => {
      this.patchshowHideLoading(false);
    }, 1000);
  }

  patchpageCall(idata: any): void {
    console.log(idata);
    this.patchshowHideLoading(true);
    setTimeout(() => {
      this.patchshowHideLoading(false);
    }, 1000);
  }

  patchaddTableData(): void {
    this.scheDetailsObj = new SchedulerDetails();
    this.scheDetailsObj.isActive = true;
    this.scheDetailsObj.settings = {mins: [], hour: [], days: [], week: [], months: [], weekdays: []};
    setTimeout(() => { this.addEditPatch = true; }, 1000);
  }

  getApplicationList(): void {
    this.baseService.doRequest(`/api/scheduler/dummy/getChocoApplicationList`, 'post',
      {body: {}}).subscribe((result: any) => {
      this.loaderService.display(false);
      result.msg.forEach((obj: any) => {
        this.applictionsHash[obj.id.trim().toLowerCase()] = obj;
      });
      this.includeApplictions = result.msg;
      this.excludeApplictions = result.msg;
    });
  }
  saveScheduler(scheDetailsObj: any, form: NgForm): void {
    if (!scheDetailsObj.includedApplication || !scheDetailsObj.includedApplication.length){
      this.toast.sToast('error', 'Please select atleast one included application');
      return;
    }
    if (scheDetailsObj.scheduler !== 'hourly') {
      const timecovert = this.hourMintTime;
      const tms = timecovert.split(':');
      const tmsendcovert = this.hourMintTimeEnd;
      const tmsend = tmsendcovert.split(':');
      scheDetailsObj.endTime = {
        hour: [tmsend[0]],
        mins: [tmsend[1]],
        days: [],
        week: [],
        months: [],
        weekdays: []
      };
      this.scheDetailsObj.settings.hour = [];
      this.scheDetailsObj.settings.mins = [];
      this.scheDetailsObj.settings.hour.push(tms[0]);
      this.scheDetailsObj.settings.mins.push(tms[1]);
      if (this.hourMintTime == '00:00'){
        this.modalService.open('patchModal');
        return;
      }
      if (this.hourMintTimeEnd == '00:00'){
        this.modalService.open('patchModal');
        return;
      }
    } else {
      this.scheDetailsObj.settings.hours = [scheDetailsObj.settings.hourly];
    }
    this.scheDetailsObj.name = this.scheDetailsObj.name === undefined ? '' : this.scheDetailsObj.name;
    scheDetailsObj.agentRef = []; scheDetailsObj.companyRef = []; scheDetailsObj.excludecompanysref = []; 
    scheDetailsObj.companyid.forEach((id: any) => {
      if (id === '*') {
        this.scheDetailsObj.isGlobal = true; scheDetailsObj.isGlobal = true;
        return;
      } else {
        this.scheDetailsObj.isGlobal = false; scheDetailsObj.isGlobal = false;
        scheDetailsObj.companyRef.push({id, name: this.companyHash[id].name});
      }
    });
    scheDetailsObj.uniqueid = 'patching_scheduler';
    const method = scheDetailsObj._id === undefined ? 'post' : 'put';
    const url = `/api/scheduler/`;
    const msg = (scheDetailsObj._id) ? 'Scheduler updated successfully!' : 'Scheduler added successfully!';
    const auditmsg = (scheDetailsObj._id) ? 'Update Scheduler' : 'Create Scheduler';
    this.loaderService.display(true, 'Saving scheduler...');
    this.baseService.doRequest(url, method, scheDetailsObj).subscribe((result: any) => {
      if (result && result.c !== null && result.u !== null) {
        this.toast.sToast('success', msg);
        this.addEditPatch = false;
        setTimeout(() => {
          this.getPatchingSchedulerData();
        }, 5000);
        form.resetForm();
        this.loaderService.display(false);
      } else {
        this.toast.sToast('error', result._id);
        this.loaderService.display(false);
      }
      this.loaderService.display(false);
    });
  }
  close(form: NgForm): void {
    this.daily = false;
    this.monthly = false;
    this.yearly = false;
    form.resetForm();
    this.addEditPatch = false;
    this.getPatchingSchedulerData();
  }
  ngOnInit(): void {
    this.patchTableOptions.pageData = [];
    this.getPatchingSchedulerData();
    this.getApplicationList();
    this.getTags();
    this.companyFilterCtrl.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroySearch)).subscribe(() => {
      this.filterCompanies();
    });
    this.companyFilterCtrl1.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroySearch)).subscribe(() => {
      this.filterCompanies();
    });

    this.getCompanies();
  }

  getTags(): void {
    this.loaderService.display(false);
    const companyId = this.currentCompany && this.currentCompany._id ? this.currentCompany._id : '';
    this.baseService.doRequest(`/api/company/tagslist`, 'post', { companyid: companyId, only_tags: true, patch_scheduler_tags: true}).subscribe((result: any) => {
      this.loaderService.display(false);
      this.tagList = result.sort();
    });
  }
  ngOnDestroy(): void {
    this.onDestroySearch.next();
    this.onDestroySearch.complete();
  }

  private filterCompanies(): void {
    if (!this.companies) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value || this.companyFilterCtrl1.value;
    if (!search) {
      this.filteredCompanies.next(this.allComp.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getCompanies(search);
  }

  closeCurrentCompany(event: any): void {
    if (!event) {
      this.filteredCompanies.next(this.allComp.slice());
    }
  }

  closeExculedCurrentCompany(event: any): void {
    if (!event) {
      this.filteredCompanies.next(this.allComp.slice());
    }
  }

  closeInclude(event: any): void {
    if (!event) {
      this.filteredCompanies.next(this.allComp.slice());
    }
  }
  closeExclude(event: any): void {
    if (!event) {
      this.filteredCompanies.next(this.allComp.slice());
    }
  }
  getCompanies(search?: string): void {
    if (!this.aS || !this.aS.isAuthenticated) {
      setTimeout(() => {
        this.getCompanies();
      }, 2000);
      return;
    }
    let cq: any;
    const cmpq = {query: {bool: {must: [{exists: {field: 'description'}}, {terms: {'_type_.keyword': ['company', 'companycreate']}}], must_not: [{match: {isAssessment: true}}]}}};
    const asmq = {query: {bool: {must: [{match: {isAssessment: true}}, {exists: {field: 'description'}}, {terms: {'_type_.keyword': ['company', 'companycreate']}}]}}};
    cq = (this.baseService.showAssessment) ? asmq : cmpq;
    if (search && search !== '') {
      cq.query.bool.must.push({ match_bool_prefix: { name: search.toLowerCase() } });
    }
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{'name.keyword': {order: 'asc'}}]);
    const fields = JSON.stringify(['name', 'c']);
    this.searching = true;
    this.companyService.getAllApiCompanyGet({q, skip, limit, sort, fields}).subscribe((result: any) => {
      if (result.data.length) {
        for (const c of result.data) {
          if (c._id) {
            this.companyHash[c._id] = c;
          }
        }
        result.data.sort((a: any, b: any) => {
          const c = (a.name) ? a.name.toLowerCase() : '';
          const d = (b.name) ? b.name.toLowerCase() : '';
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
        if (this.currentCompany && this.currentCompany._id) {
          result.data = result.data.filter((a: any) => a._id === this.currentCompany._id);
        }
        this.companies = result.data;
        if (!search) {
          this.allComp = result.data;
        }
        this.filteredCompanies.next(result.data.slice());
        this.searching = false;
      } else {
        this.filteredCompanies.next([]);
        this.searching = false;
      }
    }, (error: any) => {
      this.searching = false;
    });
  }

  updateCurrentCompany(event: any): void {
    this.scheDetailsObj.companyid = event;
    this.scheDetailsObj.excludecompany = event;
  }
  getPatchingSchedulerData(): void {
    this.schedulerData = [];
    this.loaderService.display(true, 'Getting Scheduler data');

    const query: any = {query: {bool: {must: [{exists: {field: 'settings'}}, {match: {'uniqueid.keyword': 'patching_scheduler'}},
      {exists: {field: 'uniqueid'}}], must_not: [{exists: {field: 'scanType'}}]}}};
    if (this.currentCompany && this.currentCompany._id) {
      query.query.bool.must.push({match: {'companyRef.id.keyword': this.currentCompany._id}});
    }
    this.patchTableOptions.serverSide = { service: 'schedulerService', fn: 'getAllApiSchedulerGet', q: query};

    if (this.colFilterQuery && this.colFilterQuery.multi_match) {
      query.query.bool.must.push(this.colFilterQuery);
    }
    const q = JSON.stringify(query);
    const skip =  this.patchcurrentPage;
    const limit =  this.patchTableOptions.tableOptions.pageSize;
    let sort: any = [{}];
    if (this.patchTableOptions.sortOptions && this.patchTableOptions.sortOptions.direction && this.patchTableOptions.sortOptions.direction !== '') {
    const orderArr = [ 'scheduler', 'name', 'uniqueid' , 'uifilters'];
    if (orderArr.indexOf(this.patchTableOptions.sortOptions.active) > -1) {
      sort[0][this.patchTableOptions.sortOptions.active + '.keyword'] = { order: this.patchTableOptions.sortOptions.direction };
    } else {
      sort[0][this.patchTableOptions.sortOptions.active] = { order: this.patchTableOptions.sortOptions.direction };
    }
  }
    sort = JSON.stringify(sort);
    const fields = JSON.stringify(['isActive', 'name', 'uniqueid', 'scheduler']);
    this.schedulerService.getAllApiSchedulerGet({q, skip, limit, sort})
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.schedulerData = result;
        result.data.forEach((obj: any) => {
          if (!obj.uifilters) { obj.uifilters = []; }
          if (obj.settings && obj.settings.mins.length && obj.settings.hour.length) {
            const mins = (obj.settings.mins[0] === 0) ? '00' :  ('0' + obj.settings.mins).slice(-2);
            const hour = (obj.settings.hour[0] === 0) ? '00' : ('0' + obj.settings.hour).slice(-2);
            obj.time = `${hour}:${mins}`;
            const minsend = (obj.endTime?.mins[0] === 0) ? '00' :  ('0' + obj.endTime?.mins).slice(-2);
            const hourend = (obj.endTime?.hour[0] === 0) ? '00' : ('0' + obj.endTime?.hour).slice(-2);
            obj.end_time = `${hourend}:${minsend}`;
          } else if (obj.settings && obj.settings.hours && obj.settings.hours.length) {
            obj.time = `Every ${obj.settings.hours[0]} hour(s)`;
            obj.end_time =  '';
          }
        });
        this.patchTableOptions.pageData = result.data;
        this.patchTableOptions.tableOptions.pageTotal = result.total;
        this.patchshowHideLoading(false);
      });
  }

}
export class SchedulerDetails {
  site: any;
  // tslint:disable-next-line:variable-name
  c: any;
  _id: any;
  isActive: any;
  uniqueid: any;
  settings: any;
  scantype: number;
  isGlobal: boolean;
  companyRef: any;
  exculecompanyRef:any;
  agentRef: any;
  level: string;
  sublevel: string;
  thirdlevel: string;
  ispassword: boolean;
  password: string;
  subkey: string;
  thirdvalue: any = [];
  subvalue: any = [];
  agentid: any = [];
  companyid: any = [];
  excludecompany: any = [];
  email: any = [];
  scanType: any;
  scheduler: any;
  active: any;
  siteName: any;
  siteid: any;
  assets: any;
  name: any;
  uifilters: any = [];
  reportfilters: any = '';
  include_tags?: any = []
  exclude_tags?: any = []
}
